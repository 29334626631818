module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aditi Mohanty","short_name":"Aditi Mohanty","lang":"en","start_url":"/","background_color":"#FF7C81","theme_color":"#FF7C81","display":"minimal-ui","icon":"src/images/favicon-512x512.png","crossOrigin":"anonymous","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"1f95fac710bac5d56331928ba70dc153"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-97738324-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
